import './Contact.css'
import contactImage from '../../img/contact-image.jpg'
import { useState, useRef } from 'react'
import emailjs from '@emailjs/browser';

export default function Contact() {

  const [age, setAge] = useState('')
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [number, setNumber] = useState('')

  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_dddxcwm', 'template_ody61ix', form.current, 'QGr4V0j_zb2yP1rn7')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
  };

  return (
    <div className="contact">
      <div className='work-hours'>
        <h2><span>ΩΡΑΡΙΟ</span> ΛΕΙΤΟΥΡΓΙΑΣ</h2>
        <p>Για την καλύτερη εξυπηρέτησή σας παρακαλώ να κλείσετε ραντεβού πριν επισκεφτείτε το ιατρείο μας. </p>
        <ul className='work-hours-list'>
          <li className='work-hours-list-item'>
            <span>Δευτέρα:</span>
            <span>9:00-13:00</span>
          </li>
          <li className='work-hours-list-item'>
            <span>Τρίτη:</span>
            <span>9:00-13:00 & 18:00-21:00</span>
          </li>
          <li className='work-hours-list-item'>
            <span>Τετάρτη:</span>
            <span>9:00-13:00</span>
          </li>
          <li className='work-hours-list-item'>
            <span>Πέμπτη:</span>
            <span>9:00-13:00 & 18:00-21:00</span>
          </li>
          <li className='work-hours-list-item'>
            <span>Παρασκευή:</span>
            <span>9:00-13:00 & 18:00-21:00</span>
          </li>
          <li className='work-hours-list-item'>
            <span>Σάββατο:</span>
            <span>Κλειστά (Γιατί έτσι γουστάρω)</span>
          </li>
        </ul>
      </div>
      <div className='contact-form'>
        <h2>ΕΠΙΚΟΙΝΩΝΗΣΤΕ ΜΑΖΙ ΜΟΥ</h2>
        <form ref={form} onSubmit={sendEmail}>
          <label>
            <span className={name === "" ? "" : "move-span-up"}>Ονοματεπώνυμο:</span>
            <input
              type="text"
              name="name"
              required
              onChange={(e) => setName(e.target.value)} />
          </label>
          <label>
            <span className={number === "" ? "" : "move-span-up"}>Τηλέφωνο:</span>
            <input
              type="text"
              name="number"
              required
              onChange={(e) => setNumber(e.target.value)} />
          </label>
          <label>
            <span className={email === "" ? "" : "move-span-up"}>Email:</span>
            <input
              type="email"
              name="email"
              required
              onChange={(e) => setEmail(e.target.value)} />
          </label>
          <label>
            <span className={age === "" ? "" : "move-span-up"}>Ηλικία:</span>
            <input
              name="age"
              type="text"
              required
              onChange={(e) => setAge(e.target.value)} />
          </label>
          <textarea className='form-text-area' name='message' placeholder='Περιγράψτε την ασθένεια σας...'></textarea>
          <button className='contact-button'>ΑΠΟΣΤΟΛΗ</button>
        </form>
      </div>
      <div className='location'>
        <h2>ΒΡΕΙΤΕ ΜΑΣ ΣΤΟ <span>ΧΑΡΤΗ</span></h2>
        <iframe className="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3005.730281674428!2d25.403731415280724!3d41.11858127928974!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14adec23df8767b9%3A0xd2941dd605124f13!2zzpvOv86zzrnPg8-EzrnOus-MIM6Tz4HOsc-GzrXOr86_IM6czr_Phc-Dz4TOsc-GzqwgJiDOo8-Fzr3Otc-BzrPOrM-EzrXPgg!5e0!3m2!1sen!2sgr!4v1673723008680!5m2!1sen!2sgr" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
    </div>
  )
}
