import "./Home.css"
import Hero from "../../components/Hero"
import Epemvasis from "../../components/Epemvasis"

export default function Home() {
  return (
    <div>
      {<Hero />}
      {<Epemvasis />}
    </div>
  )
}
